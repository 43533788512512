'use strict';

var nesna = nesna || {};
nesna.config = nesna.config || {}; 
nesna.sites = nesna.sites || []; 
nesna.reloadPage = false;

// default app configuration
nesna.config.show_loading_spinner_on_submit = true;

nesna.init = function() {
  var config = nesna.config;
  if(config.show_loading_spinner_on_submit) {
    $('form').on('submit', function() {
      setTimeout(function() {
        $('#overlay,.spinner-container').show();
      },500);
    });
  }
}

class User {
  constructor(obj) {
    this.id = obj.id;
    this.username = obj.username;
    this.password = obj.password;
    this.first_name = obj.first_name;
    this.last_name = obj.last_name;
    this.role = obj.role;
  }
}

nesna.login = {
  modal_id: '#forgotPassword',
  init() {
    nesna.login._initForgotPasswordModal();
  },
  _initForgotPasswordModal() {
    $(nesna.login.modal_id).find('form').on('submit', function(event) {
      event.preventDefault();
      return nesna.login._requestInformation(this, event);
    });
    $(nesna.login.modal_id).on('nesna.modal.reset', function() {
      nesna.admin.reset(this);
    });
  },
  showForgotPasswordModal(event) {
    console.log(event)
    const modal_id = nesna.login.modal_id,
      $modal = $(modal_id);
    $modal.find('.alert-warning').removeClass('alert-warning');
    if ( $(modal_id +' .alert').css('display') !== 'none' ) {
      $modal.find('.alert').html('').toggle();
    }
    if ( $modal.find('.btn.btn-primary').css('display') === 'none' ) {
      $modal.find('.btn').toggle();
    }

    nesna.modal.currentModal = $modal.modal('show');
    
    $(`${modal_id}`).find('form .modal-body').html(
      `<p>Enter your user name, and we will send you instuctions.</p>
       <label for="username">User Name</label>
       <input name="username"/>`
    );
    $modal.find('input').val('');
  },
  _requestInformation() {
    const url = '/login/forgot',
    data = $(nesna.login.modal_id +' form').serialize();
    nesna.modal.toggleLoadingSpinner(true);
    $.post( url, data, function(data, status, jqXHR) {
      if(status === "success") {
        // handle server response
        if(data.error) {
          console.log(JSON.stringify(data.error));
          $(nesna.login.modal_id +' .alert').toggleClass('alert-warning').html(data.error).show()
          nesna.modal.toggleLoadingSpinner(false);
          return false;
        } 
        if(data.msg) {
          console.log(data.msg);
          $(nesna.login.modal_id).find('.alert-success').html(`${data.msg}`).show();
          nesna.modal.toggleLoadingSpinner(false);
          $(nesna.login.modal_id).find('.btn').toggle();
        }
      } 
      return false;
    }).fail(function(x) {
      console.log(x);
      $(nesna.login.modal_id +' .alert').toggleClass('alert-warning').html("There was an error processing your request.").show();
      nesna.modal.toggleLoadingSpinner(false);
      $(nesna.login.modal_id).find('.btn').toggle();
    });
  }
}

const removeArrayItem = (arr, value) => { 
  return arr.filter(function(ele){ 
      return ele != value; 
  });
}

nesna.admin = {
  wasValidated: false,
  currentUser: null,
  activeForm: null,
  init(config) {
    nesna.admin._initUserTable();
    nesna.admin._initCreateUserModal(config.createUserModal_id);
    nesna.admin._initEditUserModal(config.editUserModal_id);
    nesna.admin._initDeleteUserModal(config.deleteUserModal_id);
  },
  _initUserTable() {
    $('table tr td:last-of-type').each(
      function() {
        let userId = $(this).parent().find('td:first-of-type').html();
        $(this).parent().append(`<td><button class="btn btn-outline-primary btn-sm" data-userId="${userId}" type="button" onclick="nesna.admin.showEditModal(this)">Edit</button></td>`);
        $(this).parent().append(`<td><button class="btn btn-outline-secondary btn-sm" data-userId="${userId}" type="button" onclick="nesna.admin.showDeleteUserModal(this)">Remove</button></td>`);
      });
    $('table').DataTable();
    },
  _initEditUserModal(id) {
    $(id).find('button[type=submit]').on('click', function() {
      nesna.admin._highlightInvalidFields(true);
    });
    $(id).find('form').on('submit', function(event) {
      event.preventDefault();
      return nesna.admin._saveUser(this, event);
      //return nesna.admin._editUser(this);
    });
    $(id).on('nesna.modal.reset', function() {
      nesna.admin.reset(this);
      nesna.admin.currentUser = null;
    });
  },
  _initCreateUserModal(id) {
    $(id).find('button[type=submit]').on('click', function() {
      nesna.admin._highlightInvalidFields(true);
    });
    $(id).find('form').on('submit', function(event) {
      event.preventDefault();
      return nesna.admin._saveUser(this, event);
    });
    $(id).on('nesna.modal.reset', function() {
      nesna.admin.reset(this);
    });
  },
  _initCreateUserModal(id) {
    $(id).find('button[type=submit]').on('click', function() {
      nesna.admin._highlightInvalidFields(true);
    });
    $(id).find('form').on('submit', function(event) {
      event.preventDefault();
      return nesna.admin._saveUser(this, event);
    });
    $(id).on('nesna.modal.reset', function() {
      nesna.admin.reset(this);
    });
  },
  _initDeleteUserModal(id) {
    $(id).find('form').on('submit', function(event) {
      event.preventDefault();
      return nesna.admin._deleteUser(this, event);
    });
    $(id).on('nesna.modal.reset', function() {
      nesna.admin.reset(this);
    });
  },
  reset(modal) {
    const modal_id = `#${modal.id}`;
    $(modal_id).find('.was-validated').removeClass('was-validated');
    $(modal_id).find('#passwordConfirmation_id').removeClass('is-invalid');
    $(modal_id).find('.form-check-input').prop( "checked", false );
    $(modal_id).find('#diamond_care_plan_options').collapse("hide");

    // reset password field and strength meter
    $(modal_id).find('.form-control.strength_input').val('').trigger("keyup");

    nesna.sites = []; 
    nesna.admin.wasValidated = false;
    nesna.admin.activeForm = modal_id;
  },
  showEditModal(editButton) {
    // reset allowed accesses section
    const modal_id = '#editUserModal_id',
      $modal = $(modal_id);
    $modal.find('.hide_on_load').show(); // show loading spinner
    $modal.find('.show_on_load').hide(); // hide allowed accesses until data is loaded
    $modal.find('input[type=checkbox]:checked').trigger('click'); // uncheck any selected accesses
    $modal.find('.collapse').collapse('hide'); // hide any expanded sections

    nesna.admin.activeForm = modal_id;
    nesna.modal.currentModal = nesna.admin.activeForm;
    $(`${modal_id}`).modal('show');
    $(`${modal_id}`).find('input').val('');
    const currentRow = $(editButton).parents('tr'); 
    const user = new User({
      id: $(currentRow).find('td:nth(0)').html(),
      username: $(currentRow).find('td:nth(4)').html(),
      password: $(currentRow).find('td:nth(5)').html(),
      first_name: $(currentRow).find('td:nth(1)').html(),
      last_name: $(currentRow).find('td:nth(2)').html(),
      role: $(currentRow).find('td:nth(3)').html()
    });
    $(`${modal_id}_id`).val(user.id);
    $(`${modal_id}_firstName`).val(user.first_name);
    $(`${modal_id}_lastName`).val(user.last_name);
    $(`${modal_id}_role`).val(user.role);
    $(`${modal_id}_username`).val(user.username);
    $(`${modal_id}_password`).val(user.password);
    $(`${modal_id}_passwordConfirmation`).val(user.password);
    $(`${modal_id}_password`).one( "click", function(){
      $(`${modal_id}_password_container`).strength_meter();
    });
    nesna.admin._getUserSelectedSites();
    console.log(JSON.stringify(user));
  },
  showCreateUserModal() {
    const modal_id = '#createUserModal_id',
      $modal = $(modal_id);

    $modal.find('.hide_on_load').hide(); // show loading spinner
    $modal.find('.show_on_load').show(); // hide allowed accesses until data is loaded
  
    nesna.admin.activeForm = modal_id;
    nesna.modal.currentModal = nesna.admin.activeForm;
    $modal.modal('show');
    $modal.find('input').val('');
    $modal.find('.form-check-input').prop( "checked", false );
    $(`${modal_id}_password_container`).strength_meter();
    nesna.sites = []; 
  },
  showDeleteUserModal(deleteButton) {
    const modal_id = '#deleteUserModal_id',
      $modal = $(modal_id);
    nesna.admin.activeForm = modal_id;
    nesna.modal.currentModal = nesna.admin.activeForm;
    $modal.modal('show');
    $modal.find('input').val('');
    const currentRow = $(deleteButton).parents('tr'); 
    const user = new User({
      id: $(currentRow).find('td:nth(0)').html(),
      first_name: $(currentRow).find('td:nth(1)').html(),
      last_name: $(currentRow).find('td:nth(2)').html(),
      role: $(currentRow).find('td:nth(3)').html(),
      username: $(currentRow).find('td:nth(4)').html()
    });
    $(`${modal_id}`).find('form .modal-body').html(
      `<p>Do you want to remove ${user.role} ${user.first_name} ${user.last_name}?</p>
       <p>This cannot  be undone!</p>
       <input name="id" type="hidden" value="${user.id}"/>
       <input name="username" type="hidden" value="${user.username}"/>`
    );
  },
  appSelect(cb) {
    //TODO: called to set the value for use_email for the admin
    let _sites = nesna.sites || [];
    let _id = $(cb).attr('id');
    let _name =  $(cb).attr('name');
    let _index = [0];
    if (/^(\d{1,3})(?=_)/.test(_name)) {
      _index = /^(\d{1,3})(?=_)/.exec(_name);
    }
    const _userEmailInput = $('[name="'+_index[0]+'_user_email"]');

    if ( _sites.includes(_id) && $(cb).prop( "checked", false ) ) {
      _sites = removeArrayItem(_sites, _id);
      $(_userEmailInput).prop( "checked", false );
    } else if ( _sites.includes(_id) && $(cb).prop( "checked", true ) ) {
      // _sites
      $(_userEmailInput).prop( "checked", true ); 
    } else {
      _sites.push(_id);
      $(_userEmailInput).prop( "checked", true );
    }
    nesna.sites = _sites
  },
  _getUserSelectedSites() {
    const url = '/admin/sites',
    data = $(nesna.admin.activeForm +' form').serialize();

    $.post( url, data, function(data, status, jqXHR) {
      if(status === "success") {
        // handle server response
        if(data.error) {
          console.log(JSON.stringify(data.error));
          nesna.admin._handleNewUserErrors(data.error);
          nesna.modal.toggleLoadingSpinner(false);
          return false;
        } 

        console.log(data.sites);
        if(data.sites) {
          //Update checkboxes
          data.sites.forEach(element => {
            $(nesna.admin.activeForm + '_cb_' + element.site_id).trigger('click');
            $(nesna.admin.activeForm + '_cb_' + element.site_id).prop( "checked", true );
            let _target = $(nesna.admin.activeForm + '_cb_' + element.site_id).attr('data-target') || undefined;
            if (_target) {
              $(nesna.admin.activeForm + ' ' + _target).find('input.form-control').val(`${ element.user}`);
            }
          });
        }
        
        $('.hide_on_load').hide();
        $('.show_on_load').show();
      } 
      return false;
    }).fail(function(x) {
      console.log(x);
    });
  },
  _editUser(form) {
    //TODO: Review, called but not being activly used, all logic is in the _saveUser method
    const modal_id = '#editUserModal_id';
    let user = new User({
      // id:  $(`${modal_id}_id`).html(),
      // first_name: $(`${modal_id}_firstName`).html(),
      // last_name: $(`${modal_id}_lastName`).html(),
      // role: $(`${modal_id}_role`).html(),
      // username: $(`${modal_id}_username`).html(),
      // password: $(`${modal_id}_password`).html(),
      // sites: nesna.sites
    });
  },
  _saveUser(form) {
    //nesna.admin.activeForm = modal_id;
    if(nesna.admin._validateNewUser(form)) {
      nesna.modal.toggleLoadingSpinner(true); 

      $(form).find('input[type="text"]').each(function(){
        this.value = $.trim(this.value);
      });
  
      const url = form.action,
            data = $(form).serialize();

      $.post( url, data, function(data, status, jqXHR) {
        if(status === "success") {
          // handle server response
          if(data.validationErrors) {
            console.log(JSON.stringify(data.validationErrors));
            nesna.admin._handleNewUserValidationErrors(data.validationErrors);
            nesna.modal.toggleLoadingSpinner(false);
            return false;
          } 
          if(data.errors) {
            console.log(JSON.stringify(data.errors));
            nesna.admin._handleNewUserErrors(data.errors);
            nesna.modal.toggleLoadingSpinner(false);
            return false;
          } 
          if(data.msg) {
            console.log(data.msg);
            $(nesna.admin.activeForm).find('input,select').attr('disabled','disabled');
            $(nesna.admin.activeForm).find('.alert-success').html(`${data.msg}`).show();
            $(nesna.admin.activeForm).find('#global_alert').html('').hide();
            $(nesna.admin.activeForm).find('.btn').toggle();
            //reload when modal is closed
            nesna.reloadPage = true;
          }
        } 
        return false;
      }).fail(function(x) {
        console.log(x);
      });
    }
    return false
  },
  _deleteUser() {
    const url = '/admin/delete',
    data = $(nesna.admin.activeForm +' form').serialize();

    $.post( url, data, function(data, status, jqXHR) {
      if(status === "success") {
        // handle server response
        if(data.error) {
          console.log(JSON.stringify(data.error));
          nesna.admin._handleNewUserValidationErrors(data.error);
          nesna.modal.toggleLoadingSpinner(false);
          return false;
        } 
        if(data.msg) {
          console.log(data.msg);
          $(nesna.admin.activeForm).find('.alert-success').html(`${data.msg}`).show();
          nesna.modal.toggleLoadingSpinner(false);
          $(nesna.admin.activeForm).find('.btn').toggle();
          //reload when modal is closed
          nesna.reloadPage = true;
        }
      } 
      return false;
    }).fail(function(x) {
      console.log(x);
    });
  },
  _handleNewUserValidationErrors(errors) {
    let messages = '';
    $(nesna.admin.activeForm + ' #global_alert').html('')
    if (typeof errors === 'Array') {
      errors.forEach(element => {
        messages +=`<p>${element.msg}</p>`
      });
    } else {
      messages = errors.msg
    }
    $(nesna.admin.activeForm + ' #global_alert').append(messages).show();
  },
  _handleNewUserErrors(errors) {
    if (typeof errors === 'Array') {
      errors.forEach(element => {
        if(element.param === "username") {
          $('#createUserModal_id_username').next('.invalid-feedback').html(element.msg);
          $('#createUserModal_id_username').addClass('is-invalid');
          $('#createUserModal_id_username').on('change', function() {
            $('#createUserModal_id_username').removeClass('is-invalid');
          });
        }
      });
    }
  },
  _highlightInvalidFields(firstTime) {
    if(firstTime) {
      nesna.admin.wasValidated = true;
      $('.form-group.needs-validation .form-control').on('change', function() {
        nesna.admin._highlightInvalidFields(false);
      });
    }
    if(nesna.admin.wasValidated) {
      $('.form-group.needs-validation .form-control:invalid').parents('.form-group.needs-validation').addClass('was-validated');
      $('.form-group.needs-validation .form-control:valid').parents('.form-group.needs-validation').removeClass('was-validated');
    }
  },
  _validateNewUser(form) {
    if(form) {
      let formValid = true;
      const _form = form
      const passwordInput=  $(form).find('[name="password"]'),
            passwordConfirmationInput = $(form).find('[name="passwordConfirmation"]')

      if(!nesna.admin._validatePasswordConfirmation(_form)) {
        formValid = false;
        passwordConfirmationInput.addClass('is-invalid');
        $(`${passwordInput.attr('id')}, ${passwordConfirmationInput.attr('id')}`).on('keyup', function() {
          nesna.admin._validatePasswordConfirmation(_form);
        });        
      }
      console.log('formValid: '+ formValid);
      return formValid;
    }
  },
  _validatePasswordConfirmation(form) {
    const passwordInput=  $(form).find('[name="password"]'),
          passwordConfirmationInput = $(form).find('[name="passwordConfirmation"]')
    
    const password = passwordInput.val(),
          passwordConfirmation = passwordConfirmationInput.val();

    if( validator.equals(password, passwordConfirmation) && (password !=='' || passwordConfirmation !=='' )) {
      passwordConfirmationInput.removeClass('is-invalid');
      return true;
    } else {
      passwordConfirmationInput.addClass('is-invalid');
      return false;
    }
  }
}

nesna.portal = {
  log(site_id, user_id, eventDescription) {    
    const url = '/portal/log',
        data = {
          site_id: site_id,
          user_id: user_id,
          eventDescription: eventDescription
    };

    $.post( url, data, function(data, status, jqXHR) {
      if(status === "success") {
        if(data && data.error) {
          console.log(JSON.stringify(data));
        }
      }
      return true;
    });
  },
  init() {
    $(function () {
      $('[data-toggle="popover"]').popover({
        container: 'body'
      })
    });
  }
};

nesna.viewLog = {
  init() {
    $('table').DataTable({
      order: [[0, 'desc']],
    });
  }
}

$(function() {
  nesna.init();
});